import React from 'react';
import Layout from '../../layouts/Layout';
import JobAlerts from '../../components/JobAlerts/JobAlerts';
import {graphql, useStaticQuery} from 'gatsby';
import CTA from '../../components/CTA/CTA';
import Hero2 from "../../components/Hero2/Hero2";
import IndexHeroImg from '../../images/benefits-bg.jpg';
import bgImg2 from "../../images/benefits-image2.jpg";
import logoWhite from "../../images/logo-w.svg";

import benImg1 from '../../images/ben-img-1.jpg';
import benImg2 from '../../images/ben-img-2.jpg';
import benImg3 from '../../images/ben-img-3.jpg';
import benImg4 from '../../images/ben-img-4.jpg';
import benImg5 from '../../images/ben-img-5.jpg';

const TeamPage = () => {
    const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
    const menuItems = [
        {
            text: 'Home',
            href: '/',
            active: false
        },
        {
            text: 'Job Opportunities',
            href: '/search/',
            active: false
        },
        {
            text: 'Benefits',
            href: '/benefits/',
            active: false
        },
        {
            text: 'Worldwide Opportunities',
            href: 'https://www.boydcorp.com/company/about-us/career-opportunities.html',
            active: false
        }
    ];
    return (
        <Layout
            menuItems={menuItems}
            title={'Boyd Careers'}
            desc={'Boyd Careers'}
        >
            <Hero2 data={data} imgSrc={IndexHeroImg}/>
            <section className={'container mx-auto px-5 py-12 text-center'}>
                <h2 className={'font-sans text-4xl font-bold text-pri'}>
                    About Boyd
                </h2>
                <div className={'flex flex-col justify-center items-center gap-10 py-8 md:flex-row'}>
                    <div
                        className={'flex flex-col text-text text-lg text-center max-w-[900px]'}
                    >
                        <p>
                            Boyd offers a comprehensive, market-competitive total rewards program to support employees'
                            personal and professional needs. Our approach to total rewards consists of four areas:
                            Compensation, Benefits, Career Development and a Positive Work Environment.
                        </p>
                    </div>
                </div>
            </section>
            <section className={'container mx-auto pb-12 text-center'}>
                <h2 className={'font-sans text-4xl font-bold text-pri mb-10'}>
                    Our Benefits
                </h2>
                <div className={'flex flex-row gap- flex-wrap justify-center text-left'}>
                    <div className={'flex flex-col md:w-1/2 p-3'}>
                        <img src={benImg1} alt={'Benefit Image 1'}/>
                        <span className={'font-sans font-bold text-pri text-3xl mt-4'}>Compensation</span>
                        <p className={'py-6 font-sans min-h-[160px]'}>Boyd offers a competitive starting salary for all positions that is driven by ongoing market analysis.</p>
                        <hr className={'border-b-4 border-pri'}/>
                    </div>
                    <div className={'flex flex-col md:w-1/2 p-3'}>
                        <img src={benImg2} alt={'Benefit Image 2'}/>
                        <span className={'font-sans font-bold text-pri text-3xl mt-4'}>Vacation and Time Off</span>
                        <p className={'py-6 font-sans min-h-[160px]'}>At Boyd, we understand the importance of work/life balance and time off is critical to maintaining a productive workforce. We offer vacation programs designed to encourage employees to recharge and enjoy time away from work.</p>
                        <hr className={'border-b-4 border-pri'}/>
                    </div>
                    <div className={'flex flex-col md:w-1/2 p-3'}>
                        <img src={benImg3} alt={'Benefit Image 3'}/>
                        <span className={'font-sans font-bold text-pri text-3xl mt-4'}>Savings and Retirement</span>
                        <p className={'py-6 font-sans min-h-[160px]'}>Boyd offers a 401(k) Profit Sharing Plan for eligible employees to assist in saving for retirement. The plan offers a powerful way to enhance your long-term financial well-being.</p>
                        <hr className={'border-b-4 border-pri'}/>
                    </div>
                    <div className={'flex flex-col md:w-1/2 p-3'}>
                        <img src={benImg4} alt={'Benefit Image 4'}/>
                        <span className={'font-sans font-bold text-pri text-3xl mt-4'}>Health Care Benefits</span>
                        <p className={'py-6 font-sans min-h-[160px]'}>The health and well-being of Boyd employees is key to our organization achieving its goals. Boyd offers a comprehensive benefits package that includes many possible choices that can be designed to fit individual employee needs and covers medical, dental, vision, disability and life insurance.</p>
                        <hr className={'border-b-4 border-pri'}/>
                    </div>
                    <div className={'flex flex-col md:w-1/2 p-3'}>
                        <img src={benImg5} alt={'Benefit Image 5'}/>
                        <span className={'font-sans font-bold text-pri text-3xl mt-4'}>Employee Development</span>
                        <p className={'py-6 font-sans min-h-[160px]'}>Boyd conducts annual performance reviews to provide regular feedback and encourage employee development through training and coaching.</p>
                        <hr className={'border-b-4 border-pri'}/>
                    </div>
                </div>
            </section>
            <section
                className={
                    'flex flex-col justify-center bg-cover bg-center lg:min-h-[700px]'
                }
                style={{backgroundImage: `url('${bgImg2}')`}}
            >
                <div
                    className={'container mx-auto flex flex-row px-5 font-sans py-12'}
                >
                    <div className={'flex flex-col lg:w-1/2'}/>
                    <div className={'flex flex-col lg:w-1/2'}>
                        <div className={'flex flex-col bg-pri p-12 max-w-[600px]'}>
                            <img src={logoWhite} alt={'Boyd Logo'}/>
                            <span className={'text-white font-sans font-bold text-3xl mt-12 mb-8'}>
								We think you will enjoy working here as much as our leadership does!
							</span>
                            <span>
								<p className={'font-sans text-white mb-6'}>
						Meet The Leadership Team That Guides Us
								</p>

							</span>
                            <a target={'_blank'} href="https://www.boydcorp.com/company/about-us/leadership.html" className={'rounded text-white border border-white p-2 px-4 mt-6 text-center w-[150px] cursor-pointer hover:text-white hover:opacity-90 focus:opacity-90'}>
                                Meet The Team
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <JobAlerts/>
            <CTA
                h2={'Ready to get started?'}
                p={
                    'Boyd is growing and wants you to join our successful team today!'
                }
            />
        </Layout>
    );
};

export default TeamPage;
